import { Box, Button, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import React from 'react'
import exitReport from '../../../../../assets/images/reportStatuses/exitReport.svg'
import openReportInformation from '../../../../../assets/images/reportStatuses/openReportInformation.svg'
import cross from '../../../../../assets/images/reportStatuses/cross.svg'

function NameReport({ reportTitle, onClose, openInfarmationReport, setOpenInfarmationReport, paddingX }) {
    const styles = {
        button: {
            minWidth: 0,
            padding: 0,
            '&:hover': {
                backgroundColor: 'transparent',
                boxShadow: 'none',
            },
        },
        image: {
            transition: 'transform 0.5s ease',
        },
        rotatedImage: {
            transform: 'rotate(-180deg)',
        },
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', paddingX: paddingX? paddingX : '15px' }}>
            <Button sx={{ minWidth: 0, padding: 0 }} onClick={onClose}>
                <img src={exitReport} alt={'Выход'} />
            </Button>
            <Typography
                ml={1}
                mr={1}
                variant={'body1'}
                sx={{ fontWeight: '400', fontSize: '24px', lineHeight: '24px', textAlign: 'center' }}
            >
                {reportTitle}
            </Typography>
            {setOpenInfarmationReport && (
                <Button sx={styles.button} onClick={() => setOpenInfarmationReport((prev) => !prev)}>
                    <Box
                        component='img'
                        src={openReportInformation}
                        alt='Открыть/Закрыть свойства'
                        sx={{
                            ...styles.image,
                            ...(openInfarmationReport ? styles.rotatedImage : {}),
                        }}
                    />
                </Button>
            )}
            <Button
                sx={{
                    minWidth: 0,
                    padding: 0,
                    marginLeft: 'auto',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
                onClick={onClose}
            >
                <Box component='img' src={cross} alt='Выход' />
            </Button>
        </Box>
    )
}

export default observer(NameReport)
