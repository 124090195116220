import { getComp } from '../utils/utils/DI'
import { computed, makeObservable, observable } from 'mobx'
import { closeModalFn, openModalFn } from '../utils/controllers/ModalController'
import {
    assignDealer,
    bulkAccept,
    bulkSetPriority,
    resetToCreatedGroup,
    updateFinancialSourceGroup,
} from '../services/ApiService'
import { isAdmin, isAdministration, isDealer, isOperator } from '../services/AccountService'
import moment from 'moment/moment'
import { notifyError, notifySuccess } from '../services/ToastifyService/ToastifyService'
import { gState } from '../utils/controllers/GlobalStateController'
import getNoun from '../utils/utils/getNoun'
import { reportingPeriods } from 'src/helpers/getReportPeriodDate'
import enter from 'src/assets/images/common/enter.svg'
import roundedOne from 'src/assets/images/common/roundedOne.svg'
import fullSubsidy from '../assets/images/financialSource/typeSubsidy.svg'
import massCreated from '../assets/images/common/massCreated.svg'

class SelectOrdersStore {
    constructor({ from, ordersStore }) {
        this.firstItem = {}
        this.selectedOrderStatus = 999 //несуществующий статус заявки он должен быть больше максимального значения ID статусов при изначальном значении
        this.selectedOrdersMonth = 999 //несуществующий месяц заявки он должен быть больше максимального значения месяцев в году при изначальном значении
        this.selectedOrdersDay = null
        this.isAssignDealerDrawerOpen = false
        this.isMassChangePriorityDrawerOpen = false
        this.isMassChangeTypeSubsidyOpen = false
        this.isMassChangeStatusDrawerOpen = false
        this.newDealer = null
        this.selectedOrders = new Set()

        this.ordersStore = ordersStore
        this.from = from
        this.dealersStore = getComp('DealersStore')
        this.territoryStore = getComp('TerritoryStore')

        makeObservable(this, {
            selectedOrders: observable,
            newDealer: observable,
            isAssignDealerDrawerOpen: observable,
            // isOrdersSelected: computed,
            // isTableRowSelected: computed,
            totalSelectedRows: computed,
            actionName: computed,
            reportingPeriod: computed,
            //isTableHeadCheckDisabled: computed,
            selectedOrderStatus: observable,
            isMassChangePriorityDrawerOpen: observable,
            isMassChangeTypeSubsidyOpen: observable,
            isMassChangeStatusDrawerOpen: observable,
            firstItem: observable,
            from: observable,
        })
    }

    someFullDotationOld = () => {
        const selectedRow = this.ordersStore.collection.filter((row) => this.selectedOrders.has(row.id))
        return selectedRow.some((row) => {
            const districts = this.territoryStore.territories[row.territory]?.Territory.Districts
            const district = districts.find((district) => district.Name === row.district)

            if (!district) return false

            const locality = district.Localities.find((locality) => locality.Name === row.locality)

            if (!locality) return false

            return locality.AllowedFinancialSourceIds.every((id) => id !== 1)
        })
    }
    checkOrderSelected = (order) => {
        return this.selectedOrders.has(order.id)
    }

    get reportingPeriod() {
        if (this.selectedOrdersDay) {
            return reportingPeriods.find(({ from, to }) => {
                return this.selectedOrdersDay >= from && this.selectedOrdersDay <= to
            })
        }
        return null
    }
    selectAllCollection = (collection) => {
        this.selectOrders(collection)
    }

    filterSelectedOrders = (collection) => {
        this.selectedOrders = new Set([...this.selectedOrders].filter((el) => collection.some((obj) => obj.id === el)))
    }

    get totalSelectedRows() {
        return this.selectedOrders.size
    }

    get isTableRowSelected() {
        return this.isOrdersSelected
    }

    getActionTabs = () => {
        return [
            {
                name: gState['intl'].formatMessage({ id: 'Назначить дилера' }),
                action: this.toggleAssignDealerDrawer,
                icon: enter,
                isActive: isAdmin(),
            },
            {
                name: gState['intl'].formatMessage({ id: 'Перевести в статус "Создана"' }),
                action: this.toggleMassChangeStatusDrawer,
                icon: massCreated,
                isActive: isAdmin(),
                forStatus: true,
            },
            {
                name: gState['intl'].formatMessage({ id: 'Изменить приоритет' }),
                action: this.toggleMassChangePriorityDrawer,
                icon: roundedOne,
                isActive: isAdmin() || isOperator(),
            },
            {
                name: gState['intl'].formatMessage({ id: 'Изменить тип оплаты' }),
                action: this.toggleMassChangeTypeSubsidy,
                icon: fullSubsidy,
                isActive: isAdmin() || isAdministration(),
            },

            {
                name: gState['intl'].formatMessage({ id: 'Принять заявки' }),
                action: this.bulkAccept,
                isActive: isDealer(),
            },
        ]
    }

    getMonth = (date) => {
        return moment(date).local(true).format('MM')
    }
    getDay = (date) => {
        return moment(date).local(true).format('DD')
    }

    checkReportingPeriod = (date) => {
        const day = this.getDay(date)
        if (this.reportingPeriod) {
            return day >= this.reportingPeriod.from && day <= this.reportingPeriod.to
        }
        return false
    }

    checkFinancialSource = (param) => {
        return this.firstItem.financialSourceId === param
    }

    checkIsOrderCanBeSelected = (date) => {
        // в случаее проблем вернуть назад по гит графу
        if (this.selectedOrders.size) {
            if (moment(date).format('YYYY') === moment(this.firstItem.installationDate).format('YYYY')) {
                return this.checkReportingPeriod(date) && this.getMonth(date) === this.selectedOrdersMonth
            }
        } else {
            return true
        }
    }

    clearSelectOrders = () => {
        this.selectedOrders.clear()
        this.selectedOrderStatus = 999
        this.selectedOrdersMonth = 999
        this.selectedOrdersDay = null
        this.firstItem = {}
    }

    get isOrdersSelected() {
        return !!this.selectedOrders.size
    }

    checkCollectionStatus = (collection) => {
        return collection.some((el) => {
            if (!this.selectedOrders.has(el.id)) {
                return !this.isOrdersSelected || el.status === this.selectedOrderStatus
            }
            return false
        })
    }
    flagResonInstallation = () => {
        return this.ordersStore.collection
            .filter((item) => [...this.selectedOrders].includes(item.id))
            .some((item) => item.reasonId === 1 || item.reasonId === 2)
    }

    checkOrderStatus = (order) => {
        return this.selectedOrderStatus === 999 || order.status === this.selectedOrderStatus
    }

    checkIsFullOfDate = (collection) => {
        const filteredByDate = collection.filter((order) => {
            return (
                this.checkIsOrderCanBeSelected(order.installationDate) &&
                this.checkFinancialSource(order.financialSourceId)
            )
        })
        return this.selectedOrders.size > 0 && filteredByDate.every((order) => this.selectedOrders.has(order.id))
    }

    selectOrders = (collection) => {
        switch (true) {
            case this.from === 'reports':
                if (!this.checkIsFullOfDate(collection)) {
                    collection.forEach((order) => {
                        if (
                            this.checkIsOrderCanBeSelected(order.installationDate) &&
                            this.checkFinancialSource(order.financialSourceId)
                        ) {
                            if (!this.selectedOrders.has(order.id)) {
                                this.selectOrder(order)
                            }
                        }
                    })
                } else {
                    this.clearSelectOrders()
                }
                break
            case this.from === 'agreement':
                if (this.selectedOrders.size < collection.length) {
                    collection.forEach((order) => {
                        if (!this.selectedOrders.has(order.id)) {
                            this.selectOrder(order)
                        }
                    })
                } else {
                    this.clearSelectOrders()
                }
                break
            default:
                if (this.selectedOrders.size === 0 || this.checkCollectionStatus(collection)) {
                    collection.forEach((order) => {
                        if (
                            this.checkShowSelect(order) &&
                            !this.selectedOrders.has(order.id) &&
                            this.checkOrderStatus(order)
                        ) {
                            this.selectOrder(order)
                        }
                    })
                } else {
                    this.clearSelectOrders()
                }
        }
    }

    selectOrder = (order) => {
        if (this.selectedOrders.size === 0) {
            this.firstItem = order
            this.selectedOrderStatus = order.status
            this.selectedOrdersMonth = this.getMonth(order.installationDate)
            this.selectedOrdersDay = this.getDay(order.installationDate)
        }
        if (this.selectedOrders.has(order.id)) {
            this.selectedOrders.delete(order.id)
            if (this.selectedOrders.size === 0) {
                this.selectedOrderStatus = 999
                this.firstItem = {}
            }
        } else {
            this.selectedOrders.add(order.id)
        }
    }

    toggleAssignDealerDrawer = () => {
        this.newDealer = null
        this.isAssignDealerDrawerOpen = !this.isAssignDealerDrawerOpen
    }

    toggleMassChangePriorityDrawer = () => {
        this.isMassChangePriorityDrawerOpen = !this.isMassChangePriorityDrawerOpen
    }

    toggleMassChangeStatusDrawer = () => {
        this.isMassChangeStatusDrawerOpen = !this.isMassChangeStatusDrawerOpen
    }

    toggleMassChangeTypeSubsidy = () => {
        this.isMassChangeTypeSubsidyOpen = !this.isMassChangeTypeSubsidyOpen
    }
    containsSubstring(errorString, substring) {
        return errorString.includes(substring)
    }

    assignDealer = () => {
        const size = this.selectedOrders.size
        openModalFn['progress-backdrop']()
        assignDealer({
            dealerId: this.dealersStore.getDealer(this.newDealer)?.subjectId,
            orderIds: [...this.selectedOrders],
            // relatedDataReset: true
        })
            .then(() => {
                const dealerName = this.dealersStore.getDealer(this.newDealer).displayName
                this.ordersStore.reLoad().finally(() => {
                    closeModalFn['progress-backdrop']()
                    const message = gState['intl'].formatMessage(
                        {
                            id: '{size} {sizeNoun} {sizeNounAction} на дилера {dealer}',
                            defaultMessage: `{size} {sizeNoun} {sizeNounAction} на дилера {dealer}`,
                        },
                        {
                            size: size,
                            sizeNoun: getNoun(size, 'заявка', 'заявки', 'заявок'),
                            sizeNounAction: getNoun(size, 'назначена', 'назначены', 'назначены'),
                            dealer: dealerName,
                        }
                    )
                    notifySuccess(message)
                })
                this.clearSelectOrders()
                this.toggleAssignDealerDrawer()
            })
            .catch((err) => {
                if (containsSubstring(err, 'Не указан тип дотации')) {
                } else {
                    notifyError('Недопустимые статусы заявок для назначения')
                }

                closeModalFn['progress-backdrop']()
            })
            .finally(() => closeModalFn['assign-dealer-submit-dialog'](), closeModalFn['progress-backdrop']())
    }
    bulkAccept = () => {
        openModalFn['progress-backdrop']()
        bulkAccept({ orderIdList: [...this.selectedOrders] })
            .then(() => {
                this.ordersStore.reLoad().finally(() => closeModalFn['progress-backdrop']())
                this.clearSelectOrders()
            })
            .catch(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    massChangePriority = (ids, priority) => {
        openModalFn['progress-backdrop']()
        bulkSetPriority({ OrderIdList: [...ids], Priority: priority })
            .then(() => {
                this.ordersStore.reLoad().finally(() => closeModalFn['progress-backdrop']())
                this.clearSelectOrders()
                this.toggleMassChangePriorityDrawer()
                notifySuccess('Приоритет заявок обновлен')
            })
            .catch(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    massChangeTypeSubsidy = (ids, financialSourceId) => {
        openModalFn['progress-backdrop']()
        updateFinancialSourceGroup({ orderIds: [...ids], financialSourceId: financialSourceId })
            .then(() => {
                this.ordersStore.reLoad().finally(() => closeModalFn['progress-backdrop']())
                this.clearSelectOrders()
                this.toggleMassChangeTypeSubsidy()
                notifySuccess(ids.length > 1 ? 'Тип оплаты заявок обновлен' : 'Тип оплаты заявки обновлен')
            })
            .catch(() => {
                closeModalFn['progress-backdrop']()
                this.toggleMassChangeTypeSubsidy()
            })
    }

    massChangeStatus = (ids) => {
        openModalFn['progress-backdrop']()
        resetToCreatedGroup({ orderIds: [...ids] })
            .then(() => {
                this.ordersStore.reLoad().finally(() => closeModalFn['progress-backdrop']())
                this.clearSelectOrders()
                this.toggleMassChangeStatusDrawer()
                notifySuccess('Заявки переведены в статус "Создана"')
            })
            .catch(() => {
                closeModalFn['progress-backdrop']()
                this.toggleMassChangeStatusDrawer()
            })
    }

    get actionName() {
        const resultArray = []

        if (isAdmin() && (this.firstItem?.status === 1 || this.firstItem?.status === 2)) {
            resultArray.push('assignDealer')
        }
        if (isDealer() && this.firstItem?.status === 2) {
            resultArray.push('acceptOrder')
        }
        if (isAdmin() && (this.firstItem?.status === 1 || this.firstItem?.status === 2)) {
            resultArray.push('changePriority')
        }
        return resultArray
    }

    checkShowSelect = (order) => {
        switch (this.from) {
            case 'reports':
                return isDealer()
            case 'assignDealer':
                return order.status === 1 && !order.dealer
            case 'acceptOrder':
                return order.status === 2
            case 'agreement':
                return order.status === 9
            default:
                return (
                    this.checkOrderStatus(order) &&
                    (isDealer() ||
                        (isAdmin() && (order.status === 1 || order.status === 2 || order.status === 4)) ||
                        (isAdministration() && (order.status === 1 || order.status === 2)) ||
                        (isOperator() && (order.status === 1 || order.status === 2)))
                )
        }
    }

    // sameCheck = (arrForCheck) => {
    //     const hash = {}
    //     let checkedCollection = []
    //     if (arrForCheck) {
    //         checkedCollection = arrForCheck.filter((el) => {
    //             let result
    //             if (this.checkShowSelect(el)) {
    //                 switch (true) {
    //                     case this.from === "reports":
    //                         if (hash.month === undefined) {
    //                             hash.month = this.getMonth(el.installationDate)
    //                         }
    //                         result = hash.month === this.getMonth(el.installationDate)
    //                         break
    //                     default:
    //                         if (hash.status === undefined) {
    //                             hash.status = el.status
    //                         }
    //                         result = hash.status === el.status
    //                 }
    //             }
    //             return result
    //         })
    //         return checkedCollection.length === arrForCheck.length
    //     }
    // }

    checkSelectDisabled = (order) => {
        return this.selectedOrderStatus > 16 ? false : this.selectedOrderStatus !== order.status
    }

    // get isTableHeadCheckDisabled() {
    //     return !this.sameCheck(this.ordersStore.collection)
    // }

    get isAssignDealer() {
        return this.actionName.includes('assignDealer')
    }

    get isAcceptOrder() {
        return this.actionName.includes('acceptOrder')
    }

    get isChangePriority() {
        return this.actionName.includes('changePriority')
    }
}

export default SelectOrdersStore
